<template>
  <div
    v-if="getLengthOf(systemMessages.data) > 0"
    class="ma-0 pa-0"
  >
    <v-card
      outlined
      color="transparent"
      class="pt-2"
    >
      <v-alert
        v-for="(n, i) in systemMessages.data"
        :key="i"
        :icon="n.icon"
        :type="n.type"
      >
        {{ n.message }}
      </v-alert>
    </v-card>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'DisplaySystemMessages',

    data () {
      return {
        showMessages: false,
      }
    },

    computed: {
      systemMessages: get('systemMessage/systemMessages'),
    },
  }
</script>
