<template>
  <v-app-bar
    id="default-app-bar"
    app
    absolute
    class="v-bar--underline non-printable"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="60"
    flat
  >
    <v-app-bar-nav-icon
      id="leftNavIcon"
      class="hidden-md-and-up"
      @click="drawer = !drawer"
    />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-toolbar-title
      class="font-weight-medium text-h3"
      v-text="getTitle"
    />

    <display-system-messages />

    <v-spacer />
    <!--
    <default-search class="hidden-sm-and-down" />
    -->
    <default-go-home />

    <default-notifications />

    <default-account />
  </v-app-bar>
</template>

<script>
  // Utilities
  import { get, sync } from 'vuex-pathify'
  import DisplaySystemMessages from '@/layouts/default/DisplaySystemMessages'

  export default {
    name: 'DefaultBar',

    components: {
      DefaultAccount: () => import(
        /* webpackChunkName: "default-account" */
        './widgets/Account'
      ),
      DefaultDrawerToggle: () => import(
        /* webpackChunkName: "default-drawer-toggle" */
        './widgets/DrawerToggle'
      ),
      DefaultGoHome: () => import(
        /* webpackChunkName: "default-go-home" */
        './widgets/GoHome'
      ),
      DefaultNotifications: () => import(
        /* webpackChunkName: "default-notifications" */
        './widgets/Notifications'
      ),
      DefaultSearch: () => import(
        /* webpackChunkName: "default-search" */
        './widgets/Search'
      ),
      DisplaySystemMessages,
    },

    computed: {
      ...sync('app', [
        'drawer',
        'mini',
      ]),
      name: get('route/name'),
      node_environment: get('app/node_environment'),
      build_environment: get('app/build_environment'),
      notifications: get('notification/notifications'),
      users: get('user/users'),
      customers: get('customer/customers'),
      getTitle () {
        let toolbarTitle = this.customers.selectedItem.description

        switch (this.name) {
          case 'Billing':
          case 'Customers':
          case 'Dashboard':
          case 'UserSettings':
            toolbarTitle = ''

            if (this.users.isCustomer) {
              toolbarTitle = `${this.customers.selectedItem.description}`
            }

            break
          default:
            switch (this.node_environment.toLowerCase()) {
              case 'production':
                switch (this.build_environment.toLowerCase()) {
                  case 'prod':
                    toolbarTitle = `${this.customers.selectedItem.description}`
                    break
                  default:
                    toolbarTitle = `${this.customers.selectedItem.description} - ${this.build_environment}`
                    break
                }
                break
              default:
                toolbarTitle = `${this.customers.selectedItem.description} - ${this.node_environment} of ${this.build_environment}`
                break
            }

            break
        }

        return toolbarTitle
      },
    },

    methods: {
    },
  }
</script>
